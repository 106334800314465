import { Box, Button, Modal, Tab, Tabs } from '@material-ui/core'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Switch from '@mui/material/Switch'
import { DateRangePicker } from 'react-date-range'
import FormControlLabel from '@mui/material/FormControlLabel'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import CloseIcon from '@mui/icons-material/Close'
import warning from '../../../assets/warning.svg'
import notice from '../../../assets/notice.svg'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

import {
  ColumnChooser,
  DragDropProvider,
  ExportPanel,
  Grid,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableFixedColumns,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import {
  CustomPaging,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core'
import Input from '@mui/material/Input'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import { alpha, styled } from '@mui/material/styles'
import { GridExporter } from '@devexpress/dx-react-grid-export'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import saveAs from 'file-saver'
import useStateValue from '../../../hooks/useStateValue'
import Container from '../../Reusable/Container'
import SelectInput from '../../Reusable/Widgets/SelectInput'
import Fixed from '../../Reusable/Fixed'
import PageHeading from '../../Reusable/PageHeading'
import PageTitle from '../../Reusable/PageTitle'
import TabPanel from '../../Reusable/TabPanel'
import useAPILocal from '../../../hooks/useAPILocal'
import bolAPI from '../../../api/bol'
import routes from '../../../routes/route_contants'
import Carrier from '../../../assets/carrier.svg'
import Receiver from '../../../assets/receiver.svg'
import Shipper from '../../../assets/shipper.svg'
import LoadingIndicator from '../Tabs/Loading'
import { dateFormatPattern } from '../../../helpers/util'

const onSave = workbook => {
  workbook.xlsx.writeBuffer().then(buffer => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'DataGrid.xlsx'
    )
  })
}

const TABS = [
  { label: 'Summary' },
  { label: 'Upcoming' },
  { label: 'Previous' },
]

const PREFIX = 'Demo'
const classes = {
  tableStriped: `${PREFIX}-tableStriped`,
  input: `${PREFIX}-input`,
  label: `${PREFIX}-label`,
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
  selector: `${PREFIX}-selector`,
}

const StyledButton = styled(Button)(() => ({
  [`&.${classes.button}`]: {
    fontSize: '14px',
    paddingLeft: '8px',
    paddingRight: '8px',
    height: '32px',
  },
}))

const ResetWidthButton = props => {
  const { resetWidths } = props
  return (
    <StyledButton
      onClick={resetWidths}
      variant="outlined"
      size="medium"
      className={classes.button}
    >
      Reset widths to default
    </StyledButton>
  )
}

const StyledTable = styled(Table.Table)(({ theme }) => ({
  [`&.${classes.tableStriped}`]: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
    },
  },
}))

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, warning }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: warning ? '#D15D4A' : '#1A1A1A',
    color: 'white',
    fontSize: '14px',
    lineHeight: '15px',
    fontFamily: 'Lato',
    fontWeight: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: warning ? '#D15D4A' : '#1A1A1A', // sets the color of the arrow
  },
}))
const TableComponent = props => (
  <StyledTable {...props} className={classes.tableStriped} />
)

const defaultColumnWidth = [
  {
    columnName: 'estimatedShipment',
    width: 180,
  },
  {
    columnName: 'customerPoFilter',
    width: 180,
  },
  {
    columnName: 'bolNoFilter',
    width: 180,
  },
  {
    columnName: 'shipperNameFilter',
    width: 180,
  },
  {
    columnName: 'carrierNameFilter',
    width: 180,
  },
  {
    columnName: 'receiverNameFilter',
    width: 180,
  },
  {
    columnName: 'productNameFilter',
    width: 180,
  },
  {
    columnName: 'productCodeFilter',
    width: 180,
  },
  {
    columnName: 'status',
    width: 250,
  },
  {
    columnName: 'estimatedArrival',
    width: 180,
  },
  {
    columnName: 'tenant_role',
    width: 180,
  },
]

const ResizingPanel = props => (
  <Plugin name="ResizingPanel">
    <Template name="toolbarContent">
      <TemplatePlaceholder />
      <ResetWidthButton {...props} />
      <FormControlLabel
        labelPlacement="start"
        onChange={e => {
          props.setShowFilters(e.target.checked)
          props.setDefaultFilters()
        }}
        checked={props.showFilters}
        control={<Switch defaultChecked />}
        label="Filters"
      />
      {/* <ModeSelector {...props} /> */}
    </Template>
  </Plugin>
)

const getType = item => {
  switch (item) {
    case 'receiver':
      return Receiver
    case 'carrier':
      return Carrier
    case 'shipper':
      return Shipper
    default:
      break
  }
}

const columns = [
  {
    name: 'estimatedShipment',
    title: 'Ship Date',
    getCellValue: row =>
      row?.estimatedShipment
        ? moment(row?.estimatedShipment).format(dateFormatPattern())
        : 'Not set',
  },
  {
    name: 'customerPoFilter',
    title: 'Customer PO',
    getCellValue: row => row?.customerPo,
  },
  {
    name: 'bolNoFilter',
    title: 'BOL No.',
    getCellValue: row => row?.bolNo,
  },
  {
    name: 'shipperNameFilter',
    title: 'Shipper',
    getCellValue: row => row?.shipper?.tenant_name,
  },
  {
    name: 'carrierNameFilter',
    title: 'Carrier',
    getCellValue: row => row?.route?.carrier?.tenant_name,
  },
  {
    name: 'receiverNameFilter',
    title: 'Receiver',
    getCellValue: row => row?.receiver?.tenant_name,
  },
  {
    name: 'productNameFilter',
    title: 'Product Name',
    getCellValue: row => row?.bolItems?.[0]?.product?.name,
  },
  {
    name: 'productCodeFilter',
    title: 'Product Number',
    getCellValue: row => row?.bolItems?.[0]?.product?.code,
  },
  {
    name: 'status',
    title: 'Status',
    getCellValue: row => row?.status.replaceAll('_', ' '),
  },
  {
    name: 'estimatedArrival',
    title: 'ETA',
    getCellValue: row =>
      row?.estimatedArrival
        ? `${moment(row?.estimatedArrival).format(dateFormatPattern())}`
        : '-',
  },
  {
    name: 'tenant_role',
    title: 'Role',
    getCellValue: row =>
      row.role.map(item => {
        getType(item)
        return (
          <img
            style={{ width: '22px', marginRight: '10px' }}
            src={getType(item)}
          />
        )
      }),
  },
]

const UnitsFilterCell = ({ filter, onFilter, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [dateValue, setDateValue] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
  })

  return (
    <TableCell
      sx={{
        width: '100%',
        p: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        style={{
          position: 'absolute',
          left: '30%',
          top: '30%',
          width: 'fit-content',
          height: 'fit-content',
          paddingBottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
        }}
      >
        <>
          <DateRangePicker
            ranges={[dateValue]}
            onChange={(val) => setDateValue(val.selection)}
          />
          <Button
            variant="contained"
            onClick={() => {
              onFilter({ selection: dateValue })
              setIsOpen(false)
            }}
            color="primary"
            style={{
              width: 'fit-content',
              alignSelf: 'flex-end',
              marginTop: '10px',
            }}
          >
            Done
          </Button>
        </>
      </Modal>

      <Input
        sx={{ fontSize: '14px', width: '100%' }}
        value={`${
          filter?.selection?.startDate
            ? moment(filter?.selection?.startDate).format('MM/DD/YYYY')
            : 'Start date'
        } -  ${
          filter?.selection?.endDate
            ? moment(filter?.selection?.endDate).format('MM/DD/YYYY')
            : 'End date'
        }`}
        placeholder="Filter..."
        inputProps={{
          style: { height: 'inherit' },
          min: 1,
          max: 4,
        }}
        onClick={() => {
          if (filter?.selection) {
            setDateValue(filter.selection)
          }
          setIsOpen(true)
        }}
      />
      <CloseIcon
        style={{ width: '20px', color: 'red' }}
        onClick={() => {
          onFilter(undefined)
        }}
      />
    </TableCell>
  )
}

const DropdownFilter = ({ filter, onFilter }) => {
  return (
    <TableCell sx={{ padding: 0 }}>
      <SelectInput
        noLabel={true}
        options={[
          { value: 'shipper', label: 'Shipper' },
          { value: 'receiver', label: 'Receiver' },
          { value: 'carrier', label: 'Carrier' },
        ]}
        onChange={e => {
          onFilter({ value: e })
        }}
        value={filter?.value ? filter?.value : ''}
      />
    </TableCell>
  )
}

const FilterCell = props => {
  const { column } = props
  if (
    column.name === 'estimatedShipment' ||
    column.name === 'estimatedArrival'
  ) {
    return <UnitsFilterCell {...props} />
  }
  if (column.name === 'tenant_role') {
    return <DropdownFilter {...props} />
  }
  return <TableFilterRow.Cell {...props} />
}

function OrderList() {
  const [showFilters, setShowFilters] = useState(false)
  const [{ table_filters }, dispatch] = useStateValue()
  const history = useHistory()
  const initialFilters = {
    limit: 50,
    sorting: [],
    filters: [],
    columnOrder: [
      'estimatedShipment',
      'shipperNameFilter',
      'carrierNameFilter',
      'receiverNameFilter',
      'productNameFilter',
      'productCodeFilter',
      'customerPoFilter',
      'bolNoFilter',
      'status',
      'estimatedArrival',
      'tenant_role',
    ],

    columnWidths: defaultColumnWidth,
    resizingMode: 'widget',
    hiddenColumnNames: [],
  }
  const state =
    table_filters?.columnOrder.includes('customerPo') ||
    table_filters?.columnOrder.includes('createdOn') ||
    !table_filters
      ? initialFilters
      : table_filters
  const setState = newState =>
    dispatch({
      type: `SET_TABLE_FILTERS`,
      table_filters: newState,
    })
  const exporterRef = useRef(null)
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid()
  }, [exporterRef])
  const [currentTab, setCurrentTab] = useState(0)
  const [searchStr, setSearchStr] = useState(undefined)
  const [page, setPage] = useState(0)
  const today = new Date()
  const tomorrow = new Date()
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)
  tomorrow.setDate(today.getDate() + 1)

  const API = useAPILocal(bolAPI.list)
  const handleSearch = val => {
    if (val?.length >= 3) setSearchStr(val)
    else setSearchStr(undefined)
  }

  function mapDateSelectionFilters() {
    return state.filters.reduce((prev, curr) => {
      prev = { ...prev }
      if (
        ['estimatedShipment'].includes(curr.columnName) &&
        curr?.selection?.startDate &&
        curr?.selection?.endDate
      ) {
        prev.estimatedShipmentFilterStart = curr?.selection?.startDate
        prev.estimatedShipmentFilterEnd = curr?.selection?.endDate
      } else if (
        ['estimatedArrival'].includes(curr.columnName) &&
        curr?.selection?.startDate &&
        curr?.selection?.endDate
      ) {
        prev.estimatedArrivalFilterStart = curr?.selection?.startDate
        prev.estimatedArrivalFilterEnd = curr?.selection?.endDate
      } else {
        prev[curr.columnName] = curr.value
      }
      return prev
    }, {})
  }

  const handleFilter = () => {
    const filters = mapDateSelectionFilters()
    const ordering = mapOrdering(state.sorting?.[0]?.columnName)
    const sorting = {
      ordering: ordering,
      orderDirection: state.sorting?.[0]?.direction?.toUpperCase(),
    }
    let apiRequest = {
      start_date: currentTab === 1 ? tomorrow : undefined,
      end_date: currentTab === 2 ? yesterday : undefined,
      ...filters,
      search: searchStr,
      limit: state.limit,
      page: page + 1,
    }
    if (sorting.ordering) apiRequest = { ...apiRequest, ...sorting }
    API.request(apiRequest)
  }

  function mapOrdering(columnName) {
    switch (columnName) {
      case 'estimatedShipment':
        return 'estimatedShipment'
      case 'shipperNameFilter':
        return 'shipper.tenantName'
      case 'carrierNameFilter':
        return 'route.carrier.tenantName'
      case 'receiverNameFilter':
        return 'receiver.tenantName'
      case 'productNameFilter':
        return ''
      case 'productCodeFilter':
        return ''
      case 'customerPoFilter':
        return 'customerPo'
      case 'bolNoFilter':
        return 'bolNo'
      case 'status':
        return 'status'
      case 'estimatedArrival':
        return 'estimatedArrival'
      case 'tenant_role':
        return ''
      default:
        return ''
    }
  }

  useEffect(() => {
    const filters = mapDateSelectionFilters()
    const ordering = mapOrdering(state.sorting?.[0]?.columnName)
    const sorting = {
      ordering: ordering,
      orderDirection: state.sorting?.[0]?.direction?.toUpperCase(),
    }
    let temp = {
      start_date: currentTab === 1 ? tomorrow : undefined,
      end_date: currentTab === 2 ? yesterday : undefined,
      ...filters,
      search: searchStr,
      limit: state.limit,
      page: page + 1,
    }
    if (sorting.ordering) temp = { ...temp, ...sorting }
    API.request(temp)
  }, [JSON.stringify(state.sorting), page, state.limit, searchStr, currentTab])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleFilter()
    }, 700)
    return () => clearTimeout(debounceTimer)
  }, [JSON.stringify(state.filters)])

  useEffect(() => {
    setState({ ...state, filters: [] })
  }, [])

  const RowComponent = ({ tableRow, ...restProps }) => {
    const hasWarningIssues = tableRow.row.issues.filter(i => i.type === 'warning').length > 0
    return (
      <TableSelection.Row
        {...restProps}
        style={hasWarningIssues ? { backgroundColor: '#FBE6E5' } : {}}
      />
    )
  }

  const CellComponent = props => {
    const isFirstColumn = state.columnOrder[0] === props.column.name
    const { hasIssues, issues } = props.row

    if (isFirstColumn && hasIssues) {
      const noticeIssues = issues.filter(i => i.type === 'notice')
      const warningIssues = issues.filter(i => i.type === 'warning')

      return (
        <VirtualTable.Cell {...props}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {warningIssues.length > 0 && (
              <CustomTooltip
                title={`Bol No.: ${warningIssues[0].bolNo}${warningIssues.map(
                  i => ` ${i.text}`
                )}`}
                arrow
                warning
              >
                <img
                  src={warning}
                  alt="warning"
                  style={{ width: '20px', height: '20px', marginRight: '5px' }}
                />
              </CustomTooltip>
            )}
            {noticeIssues.length > 0 && (
              <CustomTooltip
                title={`Bol No.: ${noticeIssues[0].bolNo}${noticeIssues.map(
                  i => ` ${i.text}`
                )}`}
                arrow
                warning={false}
              >
                <img
                  src={notice}
                  alt="notice"
                  style={{ width: '20px', height: '20px', marginRight: '5px' }}
                />
              </CustomTooltip>
            )}
            {props.value}
          </div>
        </VirtualTable.Cell>
      )
    }
    return <VirtualTable.Cell {...props}>{props.value}</VirtualTable.Cell>
  }

  return (
    <Container pt={3} px={3}>
      <Fixed>
        <PageHeading>
          <PageTitle title="Orders" />

          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(routes.ADD_SHIPMENT)}
          >
            Add Order
          </Button>
        </PageHeading>

        <Box mt={2}>
          <PageHeading alignItems="center">
            <Tabs
              value={currentTab}
              onChange={(e, v) => setCurrentTab(v)}
              indicatorColor="white"
            >
              {TABS.map((tab, index) => (
                <Tab
                  key={tab.label}
                  label={tab.label}
                  style={
                    index === currentTab
                      ? {
                          color: 'white',
                          backgroundColor: '#3298FF',
                          height: '100%',
                          borderRadius: '30px',
                        }
                      : {}
                  }
                />
              ))}
            </Tabs>
            {/* <SearchBar onChange={handleSearch} /> */}
          </PageHeading>
        </Box>
      </Fixed>

      {API.isPending ? (
        <LoadingIndicator />
      ) : (
        TABS.map((tab, index) => (
          <TabPanel key={`PANEL-${tab.label}`} value={currentTab} index={index}>
            <Paper style={{ position: 'relative', pointer: 'cursor' }}>
              <Grid
                rows={API?.data?.results || []}
                columns={columns}
                style={{ height: '30px' }}
              >
                <DragDropProvider />
                <SearchState onValueChange={setSearchStr} />

                <VirtualTable cellComponent={CellComponent} />
                <FilteringState
                  filters={state.filters}
                  onFiltersChange={e => setState({ ...state, filters: e })}
                />
                <PagingState
                  currentPage={page}
                  onCurrentPageChange={e => setPage(e)}
                  pageSize={state.limit}
                  onPageSizeChange={e => setState({ ...state, limit: e })}
                />
                <CustomPaging totalCount={API?.data?.count || 0} />
                <SortingState
                  sorting={state.sorting}
                  onSortingChange={e => setState({ ...state, sorting: [e[0]] })}
                />
                <IntegratedSorting />
                <IntegratedFiltering />
                {/* <Table /> */}
                <SelectionState
                  selection={[]}
                  onSelectionChange={data => {
                    const index = data?.[0]
                    if (!_.isNumber(index)) return
                    history.push(
                      `${routes.ORDER_DETAILS}/${API?.data?.results?.[index]?.id}`
                    )
                  }}
                />
                <TableSelection
                  rowComponent={RowComponent}
                  selectByRowClick
                  highlightRow
                  showSelectionColumn={false}
                />
                <TableColumnResizing
                  columnWidths={state.columnWidths}
                  onColumnWidthsChange={e => {
                    setState({ ...state, columnWidths: e })
                  }}
                />
                <TableColumnReordering
                  order={state.columnOrder}
                  onOrderChange={e => setState({ ...state, columnOrder: e })}
                />
                <TableHeaderRow
                  tableComponent={TableComponent}
                  showSortingControls
                />
                {showFilters && <TableFilterRow cellComponent={FilterCell} />}
                <TableFixedColumns leftColumns={[]} rightColumns={[]} />
                <TableColumnVisibility
                  hiddenColumnNames={state.hiddenColumnNames}
                  onHiddenColumnNamesChange={e => {
                    setState({
                      ...state,
                      hiddenColumnNames: e,
                    })
                  }}
                />

                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                {/* <SearchPanel /> */}
                <PagingPanel pageSizes={[20, 50, 100, 150]} />
                <ResizingPanel
                  defaultValue={state.resizingMode}
                  changeMode={e => setState({ ...state, resizingMode: e })}
                  resetWidths={() => {
                    setState({ ...state, columnWidths: defaultColumnWidth })
                  }}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                  setDefaultFilters={() => setState({ ...state, filters: [] })}
                />
              </Grid>
              <GridExporter
                ref={exporterRef}
                rows={API?.data?.results || []}
                columns={columns}
                onSave={onSave}
              />
            </Paper>
          </TabPanel>
        ))
      )}
    </Container>
  )
}

export default OrderList
