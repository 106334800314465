import { Box, Snackbar, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Alert } from '@mui/material'
import Tooltip from '@material-ui/core/Tooltip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { PaletteColors } from '../../config/colors'
import LoadingIndicator from '../Order/Tabs/Loading'
import Container from '../Reusable/Container'
import Scrollable from '../Reusable/Scrollable'
import useStateValue from '../../hooks/useStateValue'
import LogoPlaceholderTenant from '../../assets/logoPlaceholderTenant.png'
import './companydetails.scss'
import useAPILocal from '../../hooks/useAPILocal'
import tenantAPI from '../../api/tenant'
import { formatLocation } from '../../helpers/util'

const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{ fontSize: 14, color: PaletteColors.textMedium }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

function CompanyDetails() {
  const tenantDetails = useAPILocal(tenantAPI.tenantDetails)
  const [tenantInfo, setTenantInfo] = useState()
  const [loading, setLoading] = useState(false)
  const [state] = useStateValue()

  useEffect(() => {
    if (state?.user?.user_info?.tenant?.tenant_id) {
      setLoading(true)
      tenantDetails
        .request({ tenantId: state?.user?.user_info?.tenant?.tenant_id })
        .then(res => setTenantInfo(res.data))
        .finally(() => setLoading(false))
    }
  }, [])

  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const fields = [
    { label: 'Company', value: e => e?.tenant_name },
    { label: 'Slug', value: e => (e?.slug ? e?.slug : '-') },
    {
      label: 'Headquarters',
      value: e =>
        e?.primary_location !== null
          ? formatLocation(e?.primary_location)
          : '-',
    },
    { label: 'ID', value: e => e?.tenant_id.slice(0, 5) },
    { label: 'EIN', value: e => e?.tenant_ein || '-' },
    {
      label: 'Connection String',
      value: e => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: 'fit-content',
              gap: '10px',
            }}
          >
            <Tooltip className="" title={e?.connectionToken}>
              <span>{`${e?.connectionToken.slice(0, 10)}...`}</span>
            </Tooltip>
            <ContentCopyIcon
              style={{ color: '#000000', width: '20px', cursor: 'pointer' }}
              onClick={() => {
                navigator.clipboard.writeText(e?.connectionToken)
                handleClick()
              }}
            />
          </div>
        )
      },
    },
  ]

  return (
    <Container pt={3}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        style={{ marginBottom: '20px', marginRight: '20px' }}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          Connection string copied to clipboard
        </Alert>
      </Snackbar>
      <Scrollable>
        <Box
          className="shipments-list"
          style={{
            padding: 30,
            backgroundColor: PaletteColors.backgroundPrimary,
            borderRadius: 9,
          }}
        >
          <div className="mycompanybox">
            <div className="mycompanyboxin">
              {loading ? (
                <LoadingIndicator m={4} />
              ) : (
                fields.map(field => (
                  <Box key={field.label}>
                    <Item label={field.label} value={field.value(tenantInfo)} />
                  </Box>
                ))
              )}
            </div>
            <div style={{ flex: '20%' }}>
              <img
                src={`data:image/png;base64,${tenantInfo?.tenant_logo}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = LogoPlaceholderTenant
                }}
                alt=""
                className="tenantlogo"
              />
            </div>
          </div>
        </Box>
      </Scrollable>
    </Container>
  )
}
export default CompanyDetails
